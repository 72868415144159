module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/abyss_heidiz/Amoe8a-web/src/intl","languages":["en","zh","jp"],"defaultLanguage":"en","redirect":true,"redirectDefaultLanguageToRoot":false,"ignoredPaths":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#005671","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Amoe8a","short_name":"Amoe8a","start_url":"/","display":"minimal-ui","icon":"src/asset/logo_3x.png","crossOrigin":"anonymous","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"2c4b6e2785e9c3808066e154dff71545"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
