import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import { Helmet } from "react-helmet"
import { Provider } from 'react-redux';
import { store } from '@src/reducer'

const theme = createMuiTheme({
  palette: {
    primary : {
      main : '#005671'
    },
    secondary : {
      main : '#005671'
    }
  },

  typography: {
    fontFamily: [
      '"Noto Sans TC"',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  breakpoints: {
    values: {
      xs : 0,
      sm : 576,
      md : 768,
      lg : 992,
      xl : 1200,
      xxl : 1400
    },
  },
})

export default ({ element }) => (
  <Provider store = {store}>
  <ThemeProvider theme = {theme}>
    {/* <Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-9DJPHNG9SY"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments)}
  gtag('js', new Date());

  gtag('config', 'G-9DJPHNG9SY');
</script>
</Helmet> */}
    {element}
  </ThemeProvider>
  </Provider>
)