/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import RootWrapper from "./root-wrapper"
export const wrapRootElement = RootWrapper


// export const onInitialClientRender = () => {
//   setTimeout(function() {
//       document.getElementById("#loading").style.display = "none"
//   }, 1000)
// }