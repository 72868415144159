import { createAction, handleActions } from 'redux-actions'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import produce from 'immer'


const states = {
  menu : false,
}

const reducer = handleActions({
  setState : (state, action) => {
    return produce(state, draft => {
      for (const v of action.payload) {
        draft[v.key] = v.value
      }
    })
}}, states)

const store = createStore(reducer)

// const reducers = combineReducers({ reducer })
const setState = createAction("setState")


export { reducer, store, setState }
export default store

